<template  >
<div>
  <div v-if="count>0">
          <RecentActivityListItem v-for="item in list" v-bind:key="item.index"
                    :message="item.message"
                    :time="toLocalTime(item.time)"
                    :id="item.myId"
                    :img="item.image"
                    :background="getColor(item)"
                                     >
          </RecentActivityListItem>
  </div>
  <div v-else class="text-center">
    
    <h2> No Recent Activity </h2>
    </div>
</div>
</template>



<script>
import RecentActivityListItem from "./RecentActivityListItem";


export default {
    name:"RecentActivityList",
    components:{
      RecentActivityListItem,
    },
    props: {
    myList: Array,
  },
  data: function (){
    return {
      sortedList: [],
    }
  },
  computed: {
    list: function(){
      return this.myList;
    },
    count: function(){
      return this.myList?this.myList.length:0;
    },
    moment(){
      return this.$moment;
    }

  },
  methods: {
    toLocalTime: function(time){
      var myDate;
      try {
        myDate = this.moment(time).format("ddd MMM D YYYY h:mm:ss A");
      } catch (e) {
        myDate = time;
        console.log(e);
      } finally {
        return myDate;
      }

      //return new Date(time).toString();

    },
  
  getColor: function(item){
    
    if(item && item.background) 
      return item.background;
    else{

      // TODO: Check Color based on type 
      return  "var(--light)";
    }
  }
  },
    
}
</script>