var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.count > 0
      ? _c(
          "div",
          _vm._l(_vm.list, function(item) {
            return _c("RecentActivityListItem", {
              key: item.index,
              attrs: {
                message: item.message,
                time: _vm.toLocalTime(item.time),
                id: item.myId,
                img: item.image,
                background: _vm.getColor(item)
              }
            })
          }),
          1
        )
      : _c("div", { staticClass: "text-center" }, [
          _c("h2", [_vm._v(" No Recent Activity ")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }